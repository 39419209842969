import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import {useIntl} from 'react-intl'
import {useHistory, useParams} from 'react-router-dom'
import Breadcrumbs from 'components/Breadcrumbs'
import CollectionNotFound from 'components/CollectionNotFound'
import FetchingNotification from 'components/FetchingNotification'
import ItemNotFound from 'components/ItemView/ItemNotFound'
import ItemTitle from 'components/ItemView/ItemTitle'
import ItemPreview from 'components/ItemView/ItemPreview'
import CompoundItemPagination from 'components/ItemView/CompoundItemPagination'
import ItemOptions from 'components/ItemView/ItemOptions'
import ItemText from 'components/ItemView/ItemText'
import ItemMetadata from 'components/ItemView/ItemMetadata'
import ItemSearch from 'components/ItemView/ItemSearch'
import ItemViewPager from 'components/ItemView/ItemViewPager'
import CompoundItemViewer from 'components/ItemView/CompoundItemViewer'
import Panel from 'components/Panel'
import Spinner from 'components/Spinner'
import TreeView from 'components/ItemView/TreeView'
import {CurrentItemContext} from 'contexts/CurrentItemContext'
import {CollectionContext} from 'contexts/CollectionContext'
import {useCdmEventsForItem} from 'events/useCdmEventsForItem'
import {useItem} from 'hooks/useItem'
import {generateCompoundSearchResultsObjectFromArray} from 'service/ItemSearchInfo'
import cdmClasses from 'utils/CdmClasses'
import doubleDecode from 'utils/doubleDecode'
import {transformToRegex} from 'utils/HighlightRegex'
import Parser from 'html-react-parser'
import he from 'he'
import Alert from 'react-bootstrap/lib/Alert'
import FontAwesome from 'react-fontawesome'
import './ItemView.scss'

const ItemView = (props) => {

    const intl = useIntl()
    const history = useHistory()
    const collectionAliasAndItemId = useParams()
    const [itemState, itemDispatch] = useItem()
    const collectionConfig = useContext(CollectionContext)
    const collectionIsFound = collectionConfig && collectionConfig.status === 200
    const {
        collectionAlias,
        item,
        isLoading,
        isLoadingNextPrevItem,
        isLoadingItemSearch,
        fetchError,
        statusCode} = itemState

    if(history.location && history.location.state && !history.location.state.shouldScroll) {
        window.scrollTo(0,0)
    }

    useEffect(() => { props.setMainClass(`cdm-collection-page cdm-collection-page-${collectionAlias}  cdm-item-page cdm-item-page-${item.id}`) })

    const [prevItemState, setPrevItemState] = useState()
    const [updateEventParams, setUpdateEventParams] = useState({ ...collectionAliasAndItemId, isLoading: isLoading || isLoadingItemSearch || isLoadingNextPrevItem})
    useEffect(() => {
        if (prevItemState) {
            if (prevItemState !== itemState && (!isLoading && !isLoadingItemSearch && !isLoadingNextPrevItem)) {
                setUpdateEventParams({ ...collectionAliasAndItemId })
                setPrevItemState(itemState)
            }
        } else {
            setPrevItemState(itemState)
        }
    }, [prevItemState, collectionAliasAndItemId, itemState, isLoading, isLoadingNextPrevItem, isLoadingItemSearch])
    // We can add additional properties to the 2nd parameter's object for any data change that should trigger an update event
    useCdmEventsForItem(cdmClasses.ITEM_LANDING, updateEventParams, !isLoading && !isLoadingItemSearch && !isLoadingNextPrevItem)

    const stateSearchInfo =
        history.location.state && (history.location.state.searchTerm || history.location.state.itemSearchInfo)
            ? history.location.state
            : null

    const compoundOrMonograph = item && !!item.parent

    const itemViewPageFound = () => {
        const {fetchError} = itemState
        if (fetchError) {
            return null
        } else {
            let itemTitle = compoundOrMonograph
                ? item.parent.fields.find(field => field.key === 'title').value
                : item.fields.find(field => field.key === 'title')
                    ? item.fields.find(field => field.key === 'title').value
                    : item.text

            let secondaryTitle = compoundOrMonograph
                ? item.fields.find(field => field.key === 'title')
                    ? item.fields.find(field => field.key === 'title').value
                    : ''
                : ''
            let itemDescription = item.parentId > 0
                ? item.parent.fields.find(field => field.key === 'descri')
                    ? item.parent.fields.find(field => field.key === 'descri').value
                    : ''
                : item.fields.find(field => field.key === 'descri')
                    ? item.fields.find(field => field.key === 'descri').value
                    : ''

            return (
                <Fragment>
                    <Helmet>
                        <title>{`${itemTitle} - ${doubleDecode(item.collectionName)}`}</title>
                        <meta name="description" content={itemDescription}/>
                    </Helmet>
                    <div data-id={'itemViewPageFound'}>
                        <div className={'row bar'}>
                            <div className={'col-sm-8 ItemView-mainColumn'}>
                                <ItemViewPager />
                                <div>
                                    <Breadcrumbs collectionAlias={collectionAlias} collectionName={item.collectionName}
                                                 itemTitle={itemTitle} isSingeItem={!compoundOrMonograph}/>
                                    <ItemOptions className={'ItemView-options'} isSingleItem={!compoundOrMonograph}
                                                 itemTitle={secondaryTitle ? secondaryTitle : itemTitle}
                                                 itemThumbnail={item.thumbnailUri}
                                                 hasPrintPDF={item.hasPrintPDF} parentId={item.parentId}
                                                 compoundIndex={getCompoundIndex()}
                                                 type={'top-bar'} />
                                    <ItemTitle title={itemTitle} secondaryTitle={secondaryTitle}/>
                                </div>
                            </div>
                            <div className={'col-sm-4 ItemView-sideColumn'}>
                                <ItemViewPager />
                            </div>
                        </div>
                        <div className={'row foo'}>
                            <div className={'col-sm-8 ItemView-mainColumn'}>
                                <ItemPreview />
                                {displayCompoundItemPaginator()}
                                <ItemSearch>parentId={item.parentId} collectionAlias={collectionAlias}</ItemSearch>
                                {renderItemMetadata()}
                                {renderCompoundItemViewer()}
                            </div>
                            <div className={'col-sm-4 ItemView-sideColumn'}>
                                <ItemOptions className={'ItemView-options'} isSingleItem={!compoundOrMonograph}
                                             itemTitle={secondaryTitle ? secondaryTitle : itemTitle}
                                             itemThumbnail={item.thumbnailUri}
                                             hasPrintPDF={item.hasPrintPDF} parentId={item.parentId}
                                             compoundIndex={getCompoundIndex()}
                                             type={'side-bar'} />
                                <ItemSearch>parentId={item.parentId} collectionAlias={collectionAlias}</ItemSearch>
                                {renderCompoundItemViewer()}
                                {renderBottomBorder()}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    const renderItemMetadata = () => {

        /**
         * We have to keep track of two search terms and highlight the correct one
         * If our state object has a search term within item info, a result of an item page search,
         *      that search term will take priority with regards to highlighting.
         * The "item" search term is removed when navigating to other search page results and our
         *      highlighting will default to the original search query (if there is one)
         */
        //We have to keep track of two search terms and highlight the correct one
        // - if our state object has a search term within item info, a result of executing a search on the item page
        const blankSearch = {searchTerm: '', connector: 'all', field: 'all', mode: 'all'}
        const preTransformedQueries = stateSearchInfo && stateSearchInfo.itemSearchInfo.searchTerm
            ? [{...blankSearch, searchTerm: stateSearchInfo.itemSearchInfo.searchTerm}]
            : stateSearchInfo && stateSearchInfo.searchTerm
                ? [{...blankSearch, searchTerm: stateSearchInfo.searchTerm}]
                : [blankSearch]
        const searchRegexes = transformToRegex(preTransformedQueries)
        return (
            isLoading ? null :
            <div className={'ItemView-panelContainer'}>
                {item && item.text
                    ?
                    <Panel id={item.parentId > 0 ? 'compoundItemTranscript' : 'singleItemTranscript'}
                        headerTitle={he.decode(intl.formatMessage({id: 'SITE_cdm_KEY_transcript', defaultMessage: ' '}))}
                        headerText={he.decode(intl.formatMessage({id: 'SITE_cdm_KEY_transcript', defaultMessage: ' '}))}
                        expanded={stateSearchInfo && stateSearchInfo.searchResults && stateSearchInfo.searchResults.totalResults > 0}
                        collapsible={true}>
                            <ItemText text={item.text} searchRegexes={searchRegexes} />
                    </Panel>
                    : null}

                {item && item.parentId > 0
                    ?
                    <Panel
                        id = 'compoundObjectDescription'
                        headerTitle={he.decode(intl.formatMessage({id: 'SITE_cdm_compoundobject_KEY_objectdescription', defaultMessage: ' '}))}
                        headerText={he.decode(intl.formatMessage({id: 'SITE_cdm_compoundobject_KEY_objectdescription', defaultMessage: ' '}))}
                        expanded={true}
                        collapsible={true}>
                        <ItemMetadata className={'ItemView-itemMetadata object-description'}
                                      fields={item.parent.fields}
                                      searchRegexes={searchRegexes}
                                      collectionId={collectionAlias} />
                    </Panel>
                    : null}

                    <Panel
                        id={item && item.parentId > 0 ? 'compoundItemDescription' : 'singleItemDescription'}
                        headerTitle={he.decode(intl.formatMessage({id: 'SITE_cdm_compoundobject_KEY_itemdescription', defaultMessage: ' '}))}
                        headerText={he.decode(intl.formatMessage({id: 'SITE_cdm_compoundobject_KEY_itemdescription', defaultMessage: ' '}))}
                        expanded={true}
                        collapsible={true}>
                        <ItemMetadata className={'ItemView-itemMetadata item-description'}
                                      fields={item.fields}
                                      searchRegexes={searchRegexes}
                                      collectionId={collectionAlias} />
                    </Panel>
                </div>
        )
    }

    const displayCompoundItemPaginator = () => {
        return compoundOrMonograph
            ? <CompoundItemPagination totalResults={item.parent.children.length}
                                      parentId={item.parentId}
                                      parentTitle={item.parent.fields.find(field => field.key === 'title').value}
                                      items={item.parent.children}
                                      collectionAlias={collectionAlias}
                                      searchResultsItemIndex={item.id}/>
            : undefined
    }

    const renderCompoundItemViewer = () => {
        if(compoundOrMonograph && item.parent.type === 'MONOGRAPH') {
            return (
                <div className={'ItemView-compoundItemViewerContainer'}>
                    {isLoadingItemSearch ? <Spinner /> : <TreeView searchInfo={stateSearchInfo}/>}
                </div>
            )
        } else if (compoundOrMonograph) {
            const allItems = item.parent.children
            let items = stateSearchInfo && stateSearchInfo.itemSearchInfo.records
                && generateCompoundSearchResultsObjectFromArray(stateSearchInfo.itemSearchInfo.records)
            if (items && items.length < 1) items = allItems

            let itemIds = []
            if(stateSearchInfo && stateSearchInfo.itemSearchInfo.records[0]) {
                items.forEach(item => {
                    itemIds.push(item.id)
                })
            }

            return (
                <div className={'ItemView-compoundItemViewerContainer'} data-id="compoundObjectViewerContainer">
                    <CompoundItemViewer
                        collectionAlias={collectionAlias}
                        itemId={item.id}
                        index={0}   // Index of item/cpdobj/etc/ within the browse/search results
                        parentId={item.parentId}
                        parentTitle={item.parent.fields.find(field => field.key === 'title').value}
                        items={items}
                        allItems={allItems}
                        itemIds={itemIds}   // Result item ids
                        isSearching={isLoading}
                    />
                </div>
            )
        }
        else {
            return null
        }
    }

    const checkItemFound = () => {
        return isLoading
            ? <></>
            : statusCode === 404
                ? <ItemNotFound />
                : item
                    ? Object.keys(item).length < 2
                        ? <ItemNotFound />
                        : itemViewPageFound()
                    : <></>
    }

    //Helper function to find the index of the current item within the compound object
    const getCompoundIndex = useCallback(() => {
        let indexVal = 0
        if (item.parent && item.parent.children.length > 0) {
            indexVal = item.parent.children.findIndex(childItem => {
                return item.id === childItem.id
            })
        }
        return indexVal
    }, [item])

    const renderBottomBorder = () => {
        return compoundOrMonograph
            ? <div className='ItemView-bottomBorder' />
            : <div className='ItemView-bottomSingleItemBorder' />
    }

    const collectionNotFoundMessage = he.decode(intl.formatMessage({id: 'SITE_error_KEY_error_1', defaultMessage: ' '}))
        .replace("~CDMERRORCOLLECTION~", collectionAlias)
    const errorMessage = Parser(he.decode(collectionNotFoundMessage))
    const itemFetchErrorAlert = <Alert bsStyle="danger" className={' center-block'}>
        <FontAwesome name='ban'/> {errorMessage}</Alert>

    return isLoading || !collectionConfig || !collectionConfig.body ? <FetchingNotification/> : !collectionIsFound
        ? <CollectionNotFound collectionId={collectionAlias}/>
        : <CurrentItemContext.Provider value={[itemState, itemDispatch]}>
            {isLoadingNextPrevItem || isLoadingItemSearch ? <FetchingNotification/> : null}
            <div className={'ItemView-itemViewContainer shared-box'}>
                {fetchError ? itemFetchErrorAlert : null}
                {checkItemFound()}
            </div>
        </CurrentItemContext.Provider>
}

export default ItemView
