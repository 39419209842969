import React, {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Paginator from 'components/Paginator'
import SearchResultsListCard from 'components/SearchResultsListCard'
import SearchResultsGridCard from 'components/SearchResultsGridCard'
import SaveRemoveAllItemsButton from 'components/SaveRemoveAllItemsButton'
import {LIST_VIEW} from 'constants/GridListView'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {SimpleSearchContext} from 'contexts/SimpleSearchContext'
import CardViewContext from 'hooks/useCardView'
import PageNumberContext from 'hooks/usePageNumber'
import ResultsPerPageContext from 'hooks/useResultsPerPage'
import {parseUrl} from 'query-string'

const SearchResults = () => {

    const siteConfig = useContext(SiteLayoutContext)
    const searchResults = useContext(SearchResultsContext)
    const searchRequest = useContext(SearchRequestContext)
    const [,setSimpleSearch] = useContext(SimpleSearchContext)
    const history = useHistory()
    const {query, connector} = searchRequest[0]
    const itemQuery = query.split('!').reduce((acc, curr, i) => {
        return acc + ` ${connector.split('!')[i - 1].toUpperCase()} ` + curr;
    });

    useEffect(() => {
        if(query) {
            setSimpleSearch({searchTerm: itemQuery})
        }
    }, [query, itemQuery, setSimpleSearch])

    const [page] = useContext(PageNumberContext)
    const [cardView] = useContext(CardViewContext)
    const [resultsPerPage] = useContext(ResultsPerPageContext)
    const enableSavedItems = siteConfig.enableSavedItems

    const parsedQuery = parseUrl(history.location.pathname)
    let pageNumber = history.location.state && history.location.state.page
        ? history.location.state.page
        : (parseInt(parsedQuery.page) ? parseInt(parsedQuery.page) : page ? page : 1)

    const renderSearchResultListCards = () => {
        return searchResults.items.map((item, index) => {
            return <SearchResultsListCard key={index} item={item} pageIndex={index} enableSavedItems={enableSavedItems}
                                          query={itemQuery} />
        })
    }
    const renderSearchResultGridCards = () => {
        return searchResults.items.map((item, index) => {
            return <SearchResultsGridCard key={index} item={item} pageIndex={index} enableSavedItems={enableSavedItems}
                                          query={itemQuery} />
        })
    }

    const cardsPerPage = resultsPerPage ? resultsPerPage : siteConfig.search.resultsPerPage
    const totalPages = searchResults.totalResults % cardsPerPage !== 0
        ? Math.floor(searchResults.totalResults / cardsPerPage) + 1
        : Math.floor(searchResults.totalResults / cardsPerPage)
    const normalizedPageNumber = pageNumber > totalPages || pageNumber < 1 ? 1 : pageNumber

    if (searchResults) {
        const pageInfo = {
            cardsPerPage,
            totalCollections: searchResults.totalResults,
            pageNumber: normalizedPageNumber,
            cards: searchResults.items,
            trackPage: 'search results',
            itemName: 'SITE_KEY_recordsPaginator'
        }

        return (
            <div>
                <Paginator key={1} hideInfo={false} isSearch={true}
                    pageInfo={{...pageInfo, className: 'topSearchCardPaginator'}}/>

                { enableSavedItems
                    ? <SaveRemoveAllItemsButton results={searchResults} />
                    : <></>
                }

                <div key={2}>
                    {cardView === LIST_VIEW ? renderSearchResultListCards() : renderSearchResultGridCards()}
                </div>

                <Paginator key={3} hideInfo={false} isSearch={true}
                    pageInfo={{...pageInfo, className: 'bottomSearchCardPaginator'}}/>
            </div>
        )
    }

    return []
}

export default SearchResults
