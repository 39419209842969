import React, {useState, useContext, useCallback} from 'react'
import {useIntl} from 'react-intl'
import FontAwesome from 'react-fontawesome'
import {Button, Modal, FormGroup, ControlLabel, FormControl} from 'react-bootstrap'
import {SavedItemsContext} from 'contexts/SavedItemsContext'
import {exportSavedItems} from 'service/SavedItemsService'
import useAnalytics from 'hooks/useAnalytics'
import './SavedItemsExportButton.scss'
import he from "he";
import * as ReactDOM from "react-dom/server";

const saveAs = require('file-saver')

const SavedItemsExportButton = () => {

    const intl = useIntl()
    const sendAnalytics = useAnalytics()
    const savedItems = useContext(SavedItemsContext)
    const [exportButtonState, setExportButtonState] = useState({
        show: false,
        mySelectInput: "DEFAULT",
        selectedItemsForExport: null
    })

    let num_of_selected_items = savedItems.loading ? 0 : savedItems.items.filter(item => item.selected === true).length
    const SAVED_ITEMS_TMX = ReactDOM.renderToString(
        <b>{he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsPageTitle', defaultMessage: ' '}))}</b>)
    const savedItemsExportWarning = he.decode(intl.formatMessage({
        id: 'SITE_KEY_SavedItemsExportWarning',
        defaultMessage: ' '
    }, {SAVED_ITEMS_TMX, num_of_selected_items}))

    const isDisabled = () => savedItems.buttonState === 'disabled'
        || (!savedItems.loading && savedItems.items.filter(item => item.selected).length === 0)

    const handleShow = useCallback(() => {
        setExportButtonState({
            ...exportButtonState,
            show: true
        })
    }, [exportButtonState])

    const handleClose = useCallback(() => {
        setExportButtonState({
            ...exportButtonState,
            show: false
        })
    }, [exportButtonState])

    const handleChange = useCallback((e) => {
        setExportButtonState({
            ...exportButtonState,
            show: true,
            mySelectInput: e.target.value
        })
        sendAnalytics('saved items', 'exported items', `select ${e.target.value}`)
    }, [exportButtonState, sendAnalytics])

    const createSelectedItemsList = useCallback(() => {
        const selectedItems = savedItems.items.filter(item => item.selected === true)
        const selectedItemsForExport = []
        selectedItems.forEach(item => selectedItemsForExport.push({alias: item.alias, id: item.id.toString()}))
        return selectedItemsForExport
    }, [savedItems])

    const handleOnSubmit = useCallback((e) => {
        e.preventDefault()
        const {mySelectInput} = exportButtonState
        const itemsForExport = createSelectedItemsList(savedItems)
        const d = new Date()
        const dateString = (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear()
        const fileName = mySelectInput === 'html' ? `${dateString}.${mySelectInput}` : `${dateString}.txt`
        const format = mySelectInput === 'html' ? 'html' : 'text';
        handleClose()
        exportSavedItems(itemsForExport, mySelectInput)
            .then(blob => saveAs(blob, fileName))
        sendAnalytics('saved items',
            'exported items',
            `export items as ${mySelectInput}`,
            'export items: items exported',
            {format: `${format}`})
    }, [exportButtonState, createSelectedItemsList, handleClose, savedItems, sendAnalytics])

    return (
        savedItems.loading
            ? <></>
            : <div className="ExportSavedButton-exportButton">
                <Button onClick={handleShow} bsStyle="primary" className="cdm-btn"
                        data-id="exportButton" disabled={isDisabled()}
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_KEY_ExportButton', defaultMessage: ' '}))}>
                    <FontAwesome className="ExportSavedButton-download ExportSavedButton-desktopIcon fa-lg"
                                 name="download"/>
                    <FontAwesome className="ExportSavedButton-download ExportSavedButton-mobileIcon fa-2x" name="download"/>
                    <span className="ExportSavedButton-buttonText">
                    {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_KEY_ExportButton'}))}
                </span>
                </Button>
                <Modal className="ExportSavedButton-exportModal" show={exportButtonState.show}
                       onHide={handleClose} autoFocus>
                    <Modal.Header closeButton className="ExportSavedButton-exportHeader">
                        <Modal.Title componentClass='h1'>{he.decode(intl.formatMessage({
                            id: 'SITE_KEY_ExportFiles',
                            defaultMessage: ' '
                        }))}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="ExportSavedButton-modalText"
                           dangerouslySetInnerHTML={{__html: savedItemsExportWarning}}/>
                    </Modal.Body>
                    <Modal.Footer className="ExportSavedButton-modalFooter">
                        <form>
                            <FormGroup controlId="formControlsSelect" className="ExportSavedButton-formGroup">
                                <ControlLabel>{he.decode(intl.formatMessage({
                                    id: 'SITE_KEY_ExportButton',
                                    defaultMessage: ' '
                                }))}</ControlLabel>
                                <FormControl className="ExportSavedButton-selectControl"
                                             componentClass="select" defaultValue='DEFAULT'
                                             onChange={handleChange} name="mySelectInput">
                                    <option value="DEFAULT" disabled>{he.decode(intl.formatMessage({
                                        id: 'SITE_KEY_ChooseOption',
                                        defaultMessage: ' '
                                    }))}</option>
                                    <option value="text">{he.decode(intl.formatMessage({
                                        id: 'SITE_KEY_PlainText',
                                        defaultMessage: ' '
                                    }))}</option>
                                    <option value="html">{he.decode(intl.formatMessage({
                                        id: 'SITE_KEY_SimpleHTML',
                                        defaultMessage: ' '
                                    }))}</option>
                                </FormControl>
                            </FormGroup>
                            <Button className='cdm-btn' bsStyle='default'
                                    onClick={handleClose}>{he.decode(intl.formatMessage({
                                id: 'SITE_KEY_cancel',
                                defaultMessage: ' '
                            }))}</Button>
                            <Button className="ExportSavedButton-modalDownloadButton cdm-btn" bsStyle='primary'
                                    disabled={exportButtonState.mySelectInput === "DEFAULT"}
                                    onClick={handleOnSubmit}>
                                {he.decode(intl.formatMessage({id: 'SITE_KEY_ExportButton', defaultMessage: ' '}))}
                            </Button>
                        </form>
                    </Modal.Footer>
                </Modal>
            </div>
    )
}

export default SavedItemsExportButton
