import React, {useState, useCallback, useContext, useEffect, useReducer} from 'react'
import {useIntl} from 'react-intl'
import Alert from 'react-bootstrap/lib/Alert'
import FontAwesome from 'react-fontawesome'
import {useLocation, useParams} from 'react-router-dom'
import FetchingNotification from 'components/FetchingNotification'
import {useCardView} from 'hooks/useCardView'
import {usePageNumber} from 'hooks/usePageNumber'
import {ItemPagingContext} from 'contexts/ItemPagingContext'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import he from 'he'
import CardViewContext from 'hooks/useCardView'
import {itemPagingReady} from 'hooks/useItemPaging'
import PageNumberContext from 'hooks/usePageNumber'
import ResultsPerPageContext from 'hooks/useResultsPerPage'
import {useResultsPerPage} from 'hooks/useResultsPerPage'
import Parser from 'html-react-parser'
import {useCdmEventsForCollection} from 'events/useCdmEventsForCollection'
import {search} from 'service/SearchService'
import cdmClasses from 'utils/CdmClasses'
import {parseUrlForSearchRequest} from 'utils/UrlParameters'

const Action = {
     'SET_SEARCH_REQUEST': 'setSearchRequest'
}

const actions = {
    setSearchRequest: (state, action) => {
        return action.payload
    },
    setNumberOfCollectionsSelected: (state, action) => {
        return { ...state, numberOfCollectionsSelected: action.payload }
    },
    updateFilterChanges: (state, action) => {
        return { ...state, filters: { collections: action.payload }}
    },
    saveCollectionFilterString: (state, action) => {
        return { ...state, collection: action.payload }
    },
    setHasFilterChanges: (state, action) => {
        return { ...state, hasFilterChanges: action.payload }
    }
}

const searchRequestReducer = (state, action) => {
    return actions[action.type](state, action)
}

const SearchProvider = (props) => {

    const {collectionAlias} = useParams()
    const singleCollection = collectionAlias && collectionAlias.split('!').length === 1 ? collectionAlias : null

    const intl = useIntl()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [searchErrorAlert, setSearchErrorAlert] = useState(null)

    const [cardView, cardViewDispatch] = useCardView()
    const [pageNumber, setPageNumber] = usePageNumber()
    const [,itemPagingDispatch] = useContext(ItemPagingContext)

    const [resultsPerPage, setResultsPerPage] = useResultsPerPage()
    const [searchRequest, dispatch] = useReducer(searchRequestReducer, {})
    const [searchResults, setSearchResults] = useState()

    const executeSearch = useCallback(async () => {
        setIsLoading(true)
        const searchRequestObj = await parseUrlForSearchRequest(location)
        dispatch({ type: Action.SET_SEARCH_REQUEST, payload: searchRequestObj })
        const searchResp = await search(searchRequestObj, resultsPerPage, searchRequestObj.page)
        if (searchResp.error) {
            let searchErrorAlert = null
            if(searchRequestObj.collection.split('!').length === 1) {
                const collectionNotFoundMessage = he.decode(intl.formatMessage({id: 'SITE_error_KEY_error_1', defaultMessage: ' '}))
                    .replace("~CDMERRORCOLLECTION~", searchRequestObj.collection)
                const errorMessage = Parser(he.decode(collectionNotFoundMessage))
                searchErrorAlert = <Alert bsStyle="danger" className={' center-block'}>
                    <FontAwesome name='ban'/> {errorMessage}
                </Alert>
            } else {
                searchErrorAlert = <Alert bsStyle="danger">
                    <FontAwesome name="ban"/>
                    {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_error_KEY_error_2'}))}
                </Alert>
            }
            setSearchErrorAlert(searchErrorAlert)
        } else {
            itemPagingDispatch(itemPagingReady(searchResp.items, searchResp.totalResults))
            setSearchResults(searchResp)
        }
        setIsLoading(false)
    }, [location, resultsPerPage, itemPagingDispatch, intl])

    useEffect(() => {
        executeSearch()
    }, [executeSearch, location, resultsPerPage, pageNumber])


    const [prevSearchResults, setPrevSearchResults] = useState()
    const [updateEventParams, setUpdateEventParams] = useState({ ...collectionAlias, isLoading })
    useEffect(() => {
        if (prevSearchResults) {
            if (prevSearchResults !== searchResults) {
                setUpdateEventParams({ collectionAlias, searchTerm: searchRequest.query })
                setPrevSearchResults(searchResults)
            }
        } else {
            setPrevSearchResults(searchResults)
        }
    }, [collectionAlias, searchRequest.query, searchResults, prevSearchResults])

    // We can add additional properties to the 2nd parameter's object for any data change that should trigger an update event
    const event = singleCollection ? cdmClasses.COLLECTION_SEARCH : cdmClasses.SEARCH_PAGE
    useCdmEventsForCollection(event, updateEventParams, !isLoading)

    return (
        <SearchRequestContext.Provider value={[searchRequest, dispatch]}>
            <SearchResultsContext.Provider value={searchResults}>
                <CardViewContext.Provider value={[cardView, cardViewDispatch]}>
                    <PageNumberContext.Provider value={[pageNumber, setPageNumber]}>
                        <ResultsPerPageContext.Provider value={[resultsPerPage, setResultsPerPage]}>
                            {searchResults || searchErrorAlert
                                ? props.render(isLoading, searchRequest, searchResults, searchErrorAlert)
                                : <FetchingNotification />
                            }
                        </ResultsPerPageContext.Provider>
                    </PageNumberContext.Provider>
                </CardViewContext.Provider>
            </SearchResultsContext.Provider>
        </SearchRequestContext.Provider>
    )
}

export default SearchProvider
