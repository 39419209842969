import React, {useCallback, useEffect, useState} from 'react'
import {LocaleProvider} from 'components/Locale/LocaleProvider'
import {AuthenticationContext} from "./contexts/AuthenticationContext"
import {getSiteTranslations} from 'service/Translation'
import {getAuthenticationDetails} from 'service/AuthenticationService'
import track from 'react-tracking'
import {initMetrics, sendMetrics} from 'metrics/Metrics'
import './CONTENTdmResponsiveWeb.scss'
import 'whatwg-fetch'
import LocationPartition from 'components/LocationPartition/'
import IEPage from 'components/IEPage'
import {BrowserRouter} from 'react-router-dom'
import './web-components/CDMMapWidget'

const CONTENTdmResponsiveWeb = () => {

    const initialState = window.__INITIAL_STATE__
    const localeCode = localStorage.getItem('cdm_selected_language')

    initialState.intl.locale = initialState.intl.locale.replace('_', '-')

    const defaultLocaleCode = localeCode
        ? localeCode
        : initialState.site_config.language.defaultLocale.replace('_', '-')

    const [locale, setLocale] = useState(defaultLocaleCode);
    const [siteConfigTranslations, setSiteConfigTranslations] = useState({
        messages: initialState.intl.messages,
        isLoading: false
    })

    const refreshAuthenticationDataCallback = useCallback(async () => {
        const loadAuthData = async () => {
            const newAuthData = await getAuthenticationDetails()
            newAuthData.refreshAuthenticationData = refreshAuthenticationDataCallback
            setAuthData(newAuthData)
        }
        await loadAuthData()
    }, [])

    const [authData, setAuthData] = useState({
        name: initialState.authentication
                ? initialState.authentication.name
                : '',
        isWCTAdmin: initialState.authentication
                ? initialState.authentication.isWCTAdmin
                : false,
        refreshAuthenticationData: refreshAuthenticationDataCallback
    })

    //initialState.site_config.analytics.googleAnalyticsTrackerIds = ['UA-149349443-1']
    initMetrics(initialState.site_config)

    const saveLanguage = (localeCode) => new Promise(
        (resolve) => {
            localStorage.setItem('cdm_selected_language', localeCode)
            resolve(localeCode)
        }
    )

    saveLanguage(defaultLocaleCode)

    const changeLanguage = async (localeCode) => {
        const savedLocaleCode = await saveLanguage(localeCode)
        setLocale(savedLocaleCode)
    }

    const setNewTranslations = useCallback(async () => {
        const newSiteConfigTranslations = await getSiteTranslations(initialState.site_config.preview)
        setSiteConfigTranslations({
            messages: newSiteConfigTranslations,
            isLoading: false
        })
    }, [initialState.site_config.preview])

    useEffect(() => {
        setNewTranslations()
    },[setNewTranslations, locale])

    useEffect(() => {
        const loadAuthData = async () => {
            const newAuthData = await getAuthenticationDetails()
            newAuthData.refreshAuthenticationData = refreshAuthenticationDataCallback
            setAuthData(newAuthData)
        }
        loadAuthData()
    }, [refreshAuthenticationDataCallback])



    useEffect(() => {
        return () => {
            // Remove any user selections before the end of the session
            ['gridViewEnabled', 'searchResultsPerPage'].forEach((localeStorageVar) => localStorage.removeItem(localeStorageVar))
        }
    })

    //require('components/CDMMapWidget').register();

    const render = () => {
        return siteConfigTranslations.isLoading
                ? <div>Loading...</div>
                : <LocaleProvider
                    locale={locale}
                    key={locale}
                    changeLanguage={changeLanguage}
                    {...siteConfigTranslations}>
                    { window.navigator.userAgent.match(/(MSIE|Trident)/)
                        ? <IEPage/>
                        : <AuthenticationContext.Provider value={authData}>
                            <BrowserRouter>
                                <LocationPartition siteConfig={initialState.site_config} />
                            </BrowserRouter>
                        </AuthenticationContext.Provider>
                    }
                </LocaleProvider>
    }

    return render()
}

export default track({}, {dispatch: data => sendMetrics(data)})(CONTENTdmResponsiveWeb)
